
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButton,
} from "@ionic/vue";
import { Plugins, CameraSource, CameraResultType } from "@capacitor/core";
const { Camera } = Plugins;
import { defineComponent, ref } from "vue";
export default defineComponent({
  name: "Home",
  components: {
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButton,
  },
  setup() {
    const imageUrl = ref<string | null>();
    const takePicture = async () => {
      // Otherwise, make the call:
      try {
        const image = await Camera.getPhoto({
          quality: 90,
          allowEditing: true,
          resultType: CameraResultType.DataUrl,
          source: CameraSource.Prompt,
        });
        console.log("image", image);
        // image.base64_data will contain the base64 encoded result as a JPEG, with the data-uri prefix added
        imageUrl.value = image.dataUrl;
        // can be set to the src of an image now
        console.log(image);
      } catch (e) {
        console.log("error", e);
      }
    };
    const selectPicture = async () => {
      // Otherwise, make the call:
      try {
        const image = await Camera.getPhoto({
          quality: 90,
          allowEditing: true,
          resultType: CameraResultType.DataUrl,
          source: CameraSource.Photos,
        });
        console.log("image", image);
        // image.base64_data will contain the base64 encoded result as a JPEG, with the data-uri prefix added
        imageUrl.value = image.dataUrl;
        // can be set to the src of an image now
        console.log(image);
      } catch (e) {
        console.log("error", e);
      }
    };
    return {
      takePicture,
      selectPicture,
      imageUrl,
    };
  },
});
